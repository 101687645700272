import React, { useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";


import { register } from "../services/auth.service";

import {useNavigate} from "react-router-dom";
import Container from "@mui/material/Container";
import {Avatar, Card, CardContent} from "@mui/material";
import {FormTextField} from "../components/FormTextField";
import {LoadingButton} from "@mui/lab";
import {LockOutlined} from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";

const Register: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

    interface IUserInput {
        username: string,
        email: string,
        password: string,
    }

  const initialValues: IUserInput = {
    email: "",
    username: "",
    password: ""
  };



  const validationSchema = Yup.object().shape({
    username: Yup.string()
        .test(
            "len",
            "The username must be between 3 and 20 characters.",
            (val: any) =>
                val &&
                val.toString().length >= 3 &&
                val.toString().length <= 20
        )
        .required("This field is required!"),
    email: Yup.string()
        .email("This is not a valid email.")
        .required("This field is required!"),
    password: Yup.string()
        .test(
            "len",
            "The password must be between 6 and 40 characters.",
            (val: any) =>
                val &&
                val.toString().length >= 6 &&
                val.toString().length <= 40
        )
        .required("This field is required!"),
  });

  const handleRegister = (formValue: IUserInput) => {
      setLoading(true)
    const { username, password,  email } = formValue;

    register(username, password, email).then(
      () => {
          enqueueSnackbar("User created", {variant: 'success'});
          setLoading(false)
        navigate("/login")
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

          enqueueSnackbar(resMessage, {variant: 'error'});
          setLoading(false)
      }
    );
  };

  return (

      <Container maxWidth="xs">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          <Form>
            <Card sx={{ mt: 2 }}>
              <CardContent sx={{
                display: "Flex",
                justifyContent: "center",
                m: 1,
                alignItems: "center",
                flexDirection: "column",
              }}>

              <Avatar sx={{ m: 1, bgcolor: "primary.light" }}>
                  <LockOutlined />
              </Avatar>

              <Field
                component={FormTextField}
                name="username"
                label="Username"
                className="form-control" />

                <Field
                    component={FormTextField}
                    name="email"
                    label="Email"
                    className="form-control"/>


                <Field
                    component={FormTextField}
                    name="password"
                    label="Password"
                    className="form-control"
                    type="password" />

                <LoadingButton

                    loading={loading}
                    loadingIndicator="updating…"
                    type="submit"
                    variant="contained"
                    color="primary" >Create</LoadingButton>

              </CardContent>
            </Card>
          </Form>
        </Formik>
      </Container>

  );
};

export default Register;
