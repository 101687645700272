import React, {useCallback, useMemo} from "react";
import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "./App.css";

import * as AuthService from "./services/auth.service";

import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import Note from "./pages/Note";

import EventBus from "./common/EventBus";
import {isTokenExpired} from "./services/auth.service";
import ListCardType from "./pages/cardTypes/ListCardType";
import EditCardType from "./pages/cardTypes/EditCardType";
import ListDAG from "./pages/dag/ListDAG";
import EditDAG from "./pages/dag/editDAG";
import KnowledgeGraph from "./pages/KnowledgeGraph";
import Navbar from "./components/navbar";
import {SnackbarProvider} from "notistack";
import {createTheme, CssBaseline, PaletteMode, ThemeProvider} from "@mui/material";
import {ThemePaletteModeContext} from "./components/ToggleThemePaletteMode";
import { ConfirmProvider } from "material-ui-confirm";


const App: React.FC = () => {
  const navigate = useNavigate();
  const isSystemDarkMode = localStorage.getItem('theme') === 'dark'
  const [themePaletteMode, setThemePaletteMode] = React.useState<PaletteMode>(
      isSystemDarkMode ? "dark" : "light"
  );

  const logOut = useCallback(() =>   {
    AuthService.logout();
    navigate("/login")
  }, []);


  useEffect(() => {

    const newMode = localStorage.getItem('theme') === 'light' ?  'light': 'dark';
    setThemePaletteMode( newMode);

    if (isTokenExpired()) {
      logOut();
    }

    EventBus.on("logout", logOut);

    return () => {
      EventBus.remove("logout", logOut);
    };
  }, [logOut]);

   const themeProvider = useMemo(
      () =>
          createTheme({
            palette: {
              mode: themePaletteMode
            }

          }),
      [themePaletteMode]
  );

  const themePaletteModeContextProvider = useMemo(
      () => ({
        toggleThemePaletteMode: () => {
          const newMode = themePaletteMode === 'light' ?   'dark' : 'light';
          localStorage.setItem('theme', newMode);
          setThemePaletteMode((prevMode) =>
              prevMode === "light" ? "dark" : "light"
          );

        }
      }),
      [themePaletteMode]
  );


  return (
      <ThemePaletteModeContext.Provider value={themePaletteModeContextProvider}>
        <ThemeProvider theme={themeProvider}>
          <ConfirmProvider>
            <CssBaseline />
            <SnackbarProvider maxSnack={3} autoHideDuration={6000} preventDuplicate={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}/>
            <Navbar />

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/note" element={<Note />} />
              <Route path="/prompt" element={<ListCardType />} />
              <Route path="/prompt/edit/:cardTypeId" element={<EditCardType />} />
              <Route path="/prompt/edit" element={<EditCardType />} />
              <Route path="/dag" element={<ListDAG />} />
              <Route path="/dag/edit/:dagId" element={<EditDAG />} />
              <Route path="/dag/edit" element={<EditDAG />} />
              <Route path="/knowledgeGraph" element={<KnowledgeGraph />} />

            </Routes>
          </ConfirmProvider>
        </ThemeProvider>
      </ThemePaletteModeContext.Provider>
  );
};

export default App;
