import axios from "axios";
import {BACKEND_BASE_URL} from "../constants";
import authHeader from "./auth.header";


export interface INote {
    body: string,
    created_at: string,
    deleted_at: string,
    filepath: string,
    id: string,
    status: string,
    updated_at: string
};


export const getNotes = () => {
    return axios.get<INote[]>(BACKEND_BASE_URL+"/notes", { headers: authHeader() });
};

export const sendWritten = (body: string) => {
    return axios.post(BACKEND_BASE_URL+"/notes/written", {
        body: body
    }, { headers: authHeader()});
};

export const sendAudio = (file: File ) => {
    let fd = new FormData();

    fd.append('file',file)
    return axios.post(BACKEND_BASE_URL+"/notes/audio", fd, {
        headers: {...authHeader(), ...{
        "Content-Type": "multipart/form-data",
    }}
    });
};