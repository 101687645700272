import { LockOutlined } from "@mui/icons-material";
import {
    Container,
    Box,
    Avatar,
    Typography,
    TextField,
    Button,
    Grid,
} from "@mui/material";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {isTokenExpired, login} from "../services/auth.service";
import {enqueueSnackbar} from "notistack";



const Login = () => {
    const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
    useEffect(() => {
        if (!isTokenExpired()) {
            navigate("/home");

        }

    });


  const handleLogin = async () => {
    // This is only a basic validation of inputs. Improve this as needed.

      if (username && password) {
          login(username, password).then(
              () => {
                  navigate("/home");
                  window.location.reload();
              },
              (error) => {
                  const resMessage =
                      (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                      error.message || error.toString();

                  enqueueSnackbar(resMessage, {variant: 'error'});
              }).catch();

      } else {
          enqueueSnackbar("Please provide username and password", {variant: 'error'});


      }
  };

  return (
      <>

        <Container maxWidth="xs">

            <Box component="div"
                sx={{
                    mt: 20,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "primary.light" }}>
                    <LockOutlined />
                </Avatar>
                <Typography variant="h5">Login</Typography>
                <Box  component="div" sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />

                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleLogin}
                    >
                        Login
                    </Button>
                    <Grid container justifyContent={"flex-end"}>
                        <Grid item>
                            <Link to="/register">Don't have an account? Register</Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
      </>
  );
};

export default Login;
