import axios from "axios";
import authHeader from "./auth.header";
import {BACKEND_BASE_URL} from "../constants";



export interface ICardType {
    executor: string,
    executor_parameter: Map<string, any>,
    created_at: string,
    deleted_at: string,
    description: string,
    id: string,
    name: string,
    prompt: string,
    updated_at: string
}


export const getCardTypes = () => {
    return axios.get<ICardType[]>(BACKEND_BASE_URL+"/admin/cardTypes", { headers: authHeader() });
};

export const getCardType = ( cardTypeID: string) => {
    return axios.get<ICardType>(BACKEND_BASE_URL+"/admin/cardTypes/"+ cardTypeID, { headers: authHeader() });
};

export const addCardType = (body: ICardType) => {

    return axios.post(BACKEND_BASE_URL+"/admin/cardTypes" , body, { headers: authHeader() });
};

export const updateCardType = (body: ICardType, cardTypeID: string) => {

    return axios.patch(BACKEND_BASE_URL+"/admin/cardTypes/"+ cardTypeID, body, { headers: authHeader() });
};

export const deleteCardType = ( cardTypeID: string) => {

    return axios.delete(BACKEND_BASE_URL+"/admin/cardTypes/"+ cardTypeID,  { headers: authHeader() });
};
