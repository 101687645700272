import React, {useEffect, useState} from "react";
import IUser, {getUsersProfile} from "../services/user.service";
import {AxiosError} from "axios";
import EventBus from "../common/EventBus";
import Container from "@mui/material/Container";
import {Card, CardContent} from "@mui/material";
import Typography from "@mui/material/Typography";


const Profile: React.FC = () => {
    const [user, setUser] = useState<IUser>();


    async function fetchUser() {
        try {
            const { data } = await getUsersProfile();
            setUser(data)
        } catch (e) {
            const error = e as AxiosError;

            if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
            }
        }
    }

    useEffect(() => {
        fetchUser()

    }, []);

  return (
    <Container maxWidth="xs">
        <Card sx={{ mt: 2 }}>
            <CardContent sx={{
                display: "Flex",
                justifyContent: "center",
                m: 1,
                alignItems: "left",
                flexDirection: "column",
            }}>

            <Typography variant="h5" component="div">
              <strong>{user?.username}</strong> Profile
            </Typography>


              <Typography>
                <strong>Email:</strong>  {user?.email}
              </Typography>
            <Typography>
                <strong>Role:</strong>  {user?.role}
            </Typography>

            </CardContent>
        </Card>
    </Container>
  );
};

export default Profile;
