'use client'
import React, { useState, useEffect, useRef } from 'react'
import {
    darkTheme,
    GraphCanvas,
    GraphCanvasRef, lightTheme,
    useSelection,
} from 'reagraph'
import {useTheme} from "@mui/material";
import {IGraphNode, IGraphRelationship} from "../services/kgraph.service";


export interface KGraphProps {
    nodes?: IGraphNode[]
    edges?: IGraphRelationship[]
    loading: boolean
}

const KGraph: React.FC<KGraphProps> = props => {

    const theme = useTheme();
    const graphRef = useRef<GraphCanvasRef | null>(null)

    const [nodes, setNodes] = useState([])
    const [edges, setEdges] = useState([])

    useEffect(() => {

        if (props.nodes) {

            const newNodes: any = props.nodes.map((node: IGraphNode) => {
                return {
                    id: node.id,
                    label: node.id,
                }
            })

            setNodes(newNodes)

        }

    }, [props.nodes])

    useEffect(() => {

        if (props.edges) {

            const newEdges: any = props.edges.map((relationship: IGraphRelationship) => {
                return {
                    source: relationship.source.id,
                    target: relationship.target.id,
                    id: crypto.randomUUID(),
                    label: relationship.type
                }
            })
            setEdges(newEdges)


        }
    }, [props.edges])

    const {
        selections,
        actives,
        onNodeClick,
        onCanvasClick,
        onNodePointerOver,
        onNodePointerOut
    } = useSelection({
        ref: graphRef,
        nodes,
        edges,
        pathSelectionType: 'out'
    })

    if(props.loading) return <div>Loading...</div>

    return (

            <GraphCanvas
                theme={theme.palette.mode === 'dark' ? darkTheme : lightTheme}
                nodes={nodes}
                edges={edges}
                ref={graphRef}
                selections={selections}
                actives={actives}
                labelType="all"
                draggable
                sizingType="centrality"
                onCanvasClick={onCanvasClick}
                onNodeClick={onNodeClick}
                onNodePointerOver={onNodePointerOver}
                onNodePointerOut={onNodePointerOut}
                // cameraMode="rotate"
            />
    )
}

export default KGraph;