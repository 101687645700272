import React, {useState} from 'react';
import {
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import {Card, CardActions, CardContent, Collapse} from "@mui/material";
import Typography from "@mui/material/Typography";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import CopyToClipboard from "react-copy-to-clipboard";
import Button from "@mui/material/Button";
import {ICard} from "../services/card.service";

interface CustomCardProps {
    card: ICard
}

// Define the functional component
const CustomCard: React.FC<CustomCardProps> = (props : CustomCardProps) => {
    const [expanded, setExpanded] = useState<boolean>(false)
    const concatSize = 110

    const needCollapse = props.card.body.length > concatSize

    function GetTimelineDotColor(): string {
        let color = "#808080"
        switch (props.card.type) {
            case 'red':
                color = '#ef5350'
                break;
            case 'green':
                color = '#4caf50'
                break;
            case 'blue':
                color = '#03a9f4'
                break;
        }
        return color
    }


    return (
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{m: 'auto 0'}}
                    variant="body2"
                    color="text.secondary"
                >
                    {new Date(props.card.created_at).toLocaleDateString() + " " + new Date(props.card.created_at).toLocaleTimeString()}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector/>
                    <TimelineDot sx={{
                        backgroundColor: GetTimelineDotColor()
                    }}/>
                    <TimelineConnector/>
                </TimelineSeparator>
                <TimelineContent sx={{py: '12px', px: 2 }}>
                    <Card sx={{minWidth: 275, textAlign: "left"}} >
                        <CardContent>
                            <Typography variant="h5" component="div" sx={{align: "left"}}>
                                {props.card.title}
                            </Typography>
                            <Collapse collapsedSize={150} in={expanded}>
                                <Typography onClick={() => setExpanded(!expanded)}>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        { expanded || !needCollapse ? ( props.card.body ):( props.card.body.substring(0, concatSize).concat("...") )}
                                    </ReactMarkdown>
                                </Typography>
                            </Collapse>
                            <Typography sx={{ textAlign: 'right' }}>
                                { needCollapse && ( <Button sx={{ fontStyle: 'italic' }} onClick={() => setExpanded(!expanded)}> {expanded ? ( "Read less" ):( " Read more" )}</Button>)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <CopyToClipboard text={props.card.body} >
                                <Button size="small">Copy</Button>
                            </CopyToClipboard>
                        </CardActions>
                    </Card>
                </TimelineContent>
            </TimelineItem>

        )

}

export default CustomCard;