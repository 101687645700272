import axios from "axios";
import authHeader from "./auth.header";
import {BACKEND_BASE_URL} from "../constants";

export interface ICard {
    body: string,
    created_at: string,
    deleted_at: string,
    id: string,
    status: string,
    title: string,
    type: string,
    updated_at: string

};


export const getCards = () => {
    return axios.get<ICard[]>(BACKEND_BASE_URL+"/cards",{ headers: authHeader() });
};

