import React, { useState, useEffect } from 'react';
import {addDAG, Edge, getDAG, getCreatedExecutors, IDAG, ICreatedExecutor, Node, updateDAG} from "../../services/DAGs.service";
import {Canvas, CanvasContainerProps } from 'reaflow';
import {NavigateFunction, useNavigate, useParams} from "react-router-dom";
import {AxiosError} from "axios";
import {enqueueSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import Container from "@mui/material/Container";
import {Card, Grid, TextField} from "@mui/material";
import Button from "@mui/material/Button";


const ListDAGs: React.FC = () => {
    const [DAG, setDAG] = useState<IDAG>();
    const [inputDAG, setInputDAG] = useState<IDAG>();
    const [executor, setExecutor] = useState<ICreatedExecutor[]>();
    const [loading, setLoading] = React.useState(false);
    const [uuid, setUUID] = useState<String>("");
    const [selectedDAG, setSelectedDAG] = useState<CanvasContainerProps>();
    const {dagId} = useParams<{ dagId: string }>();
    const navigate: NavigateFunction = useNavigate();

    const fetchDAG = async (dagId: string) => {
        try {
            const {data} = await getDAG(dagId)
            setDAG(data)
            updateSelectedDAG(data.nodes, data.edges)
        } catch (e) {

        }
    };

    useEffect(() => {
        fetchCreatedExecutor()
        if (dagId) {
            fetchDAG(dagId);

        } else {
            let start = crypto.randomUUID()

            let end = crypto.randomUUID()
            let data = {
                id: crypto.randomUUID(),
                name: "new DAG",
                active: false,
                nodes: [
                    {
                        "id": start,
                        "name": "start"
                    },
                    {
                        "id": end,
                        "name": "end"
                    }
                ],
                edges: [{
                    "id": crypto.randomUUID(),
                    "parent_id": start,
                    "child_id": end
                }]
            }


            setDAG(data)
            updateSelectedDAG(data.nodes, data.edges)
        }
    }, [dagId]);

    const updateSelectedDAG = (nodes: Node[] | undefined, edges: Edge[] | undefined) => {
        setSelectedDAG({
            nodes: nodes?.map((node: Node) => ({id: node.id, text: node.name})),
            edges: edges?.map((edge: Edge) => ({id: edge.id, from: edge.parent_id, to: edge.child_id})),
            pannable: false,
            zoomable: false,
            direction: "RIGHT",
            readonly: false,
            animated: false,
            maxWidth: 0,
            maxHeight: 0,

        });
    }




    const fetchCreatedExecutor = async () => {
        try {
            const {data} = await getCreatedExecutors()
            setExecutor(data)
        } catch (e) {

        }
    };



    const handleOnSubmit = async () => {
        setLoading(true);
        try {
            if (dagId) {
                await updateDAG(dagId, inputDAG as IDAG)
                enqueueSnackbar("DAG updated", {variant: 'success'});
            }else {
                const { data } = await addDAG(inputDAG as IDAG)
                enqueueSnackbar("DAG added", {variant: 'success'});
                navigate(`/dag/edit/${data.id}`);
            }


        } catch (e) {
            const error = e as AxiosError;
            enqueueSnackbar(error.toString(), {variant: 'error'});
        }
        setLoading(false);
    }


    const handleGenerateUUID = async () => {
        setUUID(crypto.randomUUID())

    }


    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

        try {
            let dag = JSON.parse(e.target.value)
            setInputDAG(dag)
            updateSelectedDAG(dag.nodes, dag.edges)
        }catch (e) {
            updateSelectedDAG(undefined, undefined)
        }

    }


    return (
        <Container >
            <h1>DAG</h1>
            <Card sx={{ m: 2, p:3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8} container >
                        <TextField
                            name="dag"
                            fullWidth
                            multiline
                            rows={20}
                            defaultValue={JSON.stringify(DAG, null, 2)}
                            onChange={handleOnChange}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Grid>
                            <h3>Available Executor</h3>
                            <ul>
                                {
                                    executor?.map((executor) => (
                                            <li key={executor.name}><b>{executor.name}</b> - <i>{executor.type}</i></li>
                                    ))
                                }

                            </ul>
                        </Grid>
                        <Grid>
                                <Button type="submit" variant="contained"   onClick={handleGenerateUUID}>
                                    Generate uuid
                                </Button>
                                <div id="uuid" style={{ marginTop:"10px" }}>{ uuid }</div>
                        </Grid>
                    </Grid>
                </Grid>

                <br />
                <Grid
                    container
                    spacing={0}

                    alignItems="center"
                    justifyContent="center"

                >
                    <LoadingButton
                        loading={loading}
                        loadingIndicator="updating…"
                        type="submit"
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleOnSubmit}>
                        Submit
                    </LoadingButton>
                </Grid>
            </Card>

            {selectedDAG && (
            <div >
                <div id="dag" style={{
                    height: "500px",
                    width: "100%",
                    marginTop:"10px"
                }}>

                <Canvas
                    edges={selectedDAG.edges}
                    nodes={selectedDAG.nodes}
                    pannable={selectedDAG.pannable}
                    zoomable={selectedDAG.zoomable}
                    direction={selectedDAG.direction}
                    readonly={selectedDAG.readonly}
                    animated={selectedDAG.animated}
                    maxWidth={selectedDAG.maxWidth}
                    maxHeight={selectedDAG.maxHeight}
                />
                </div>
            </div>
    )}
        </Container>
    );
};

export default ListDAGs;