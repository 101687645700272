import React, {useEffect, useState} from "react";
import Container from "@mui/material/Container";
import {getCards, ICard} from "../services/card.service";
import {AxiosError} from "axios";
import EventBus from "../common/EventBus";
import {Timeline} from "@mui/lab";
import CustomCard from "../components/CustomeCard";


const Home: React.FC = () => {
    const [cards, setCards] = useState<ICard[]>();

    async function fetchCards() {
        try {
            const { data } = await getCards()
            setCards(data)
        } catch (e) {
            const error = e as AxiosError;

            if (error.response && error.response.status === 401) {
                EventBus.dispatch("logout");
            }
        }
    }

    useEffect(() => {
        fetchCards()

        const intervalId = setInterval(() => {
            fetchCards()
        }, 30_000)
        return () => clearInterval(intervalId)
    }, []);


    return (
        <Container>
            <Timeline position="alternate">

                {cards?.map((card, index) => {
                    return (
                        <CustomCard key={index} card={card} />

                    )})
                }

            </Timeline>
        </Container>
    );

};

export default Home;