import React, {useEffect, useState} from "react";
import {getNotes, INote, sendAudio, sendWritten} from "../services/note.service";
import {AudioRecorder} from "react-audio-voice-recorder";
import EventBus from "../common/EventBus";
import ReactMarkdown from "react-markdown";
import Container from "@mui/material/Container";
import {Card, CardContent, Grid} from "@mui/material";

import {FormTextField} from "../components/FormTextField";
import {LoadingButton} from "@mui/lab";
import {enqueueSnackbar} from "notistack";
import {Field, Form, Formik} from "formik";
import {MuiFileInput} from "mui-file-input";
import {AxiosError} from "axios";

const Note: React.FC = () => {
    const [notes, setNotes] = useState<INote[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSending, setLoadingSending] = useState<boolean>(false);
    const [valueInputFile, setValueInputFile] = React.useState<File | null>(null)

    async function fetchNotes() {
        const { data } = await getNotes()
        setNotes(data)
    }

    useEffect(() => {
        fetchNotes()
    }, []);


    const initialValues: {
        note: string;
    } = {
        note: "",
    };
    const handleNote =  (formValue: typeof initialValues) => {

        setLoading(true);
        // Handle form submission here
        // You can access the text input valueInputFile using `text` and the audio input file using `audio`
        if (formValue.note) {
            sendWritten(formValue.note).then(
                () => {

                    enqueueSnackbar("written note created", {variant: 'success'});
                    fetchNotes();
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();


                    enqueueSnackbar(resMessage, {variant: 'error'});
                    if (error.response && error.response.status === 401) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }
        setLoading(false);
    };

    const handleFileChange = (newValue: File | null) => {
        setValueInputFile(newValue)
    }

    const handleFileSubmit = async () => {
        setLoadingSending(true)
        if (valueInputFile) {
            try {
                await sendAudio(valueInputFile)
                await fetchNotes();
                enqueueSnackbar("audio note Created", {variant: 'success'});
            } catch (e) {
                const error = e as AxiosError;
                enqueueSnackbar(error.toString(), {variant: 'error'});
            }
        }
        setLoadingSending(false)

    };

    const addAudioElement = async (blob: Blob) => {
        let audioFile = new File([blob], "audion-" + Date.now() + ".webm");
        try {
            await sendAudio(audioFile)
            enqueueSnackbar("audio note Created", {variant: 'success'});
        } catch (e) {
            const error = e as AxiosError;
            enqueueSnackbar(error.toString(), {variant: 'error'});
        }


    };



    return (

        <Container>
            <h3>Add notes</h3>
            <Grid container >
                <Grid item xs sx={{mt: 5, mr:2}} component={Card} >
                    <CardContent sx={{
                        display: "Flex",
                        justifyContent: "center",
                        m: 1,
                        alignItems: "center",
                        flexDirection: "column",
                    }}>
                        <h5 className="card-title mb-3">Register Note</h5>
                        <div className="card-text">
                            <AudioRecorder
                                onRecordingComplete={addAudioElement}
                                showVisualizer={true}
                                audioTrackConstraints={{
                                    noiseSuppression: true,
                                    echoCancellation: true,
                                }}
                            />
                        </div>
                    </CardContent>
                </Grid>
                <Grid item xs sx={{mt: 5,  ml:2}} component={Card} >
                    <CardContent sx={{
                        display: "Flex",
                        justifyContent: "center",
                        m: 1,
                        alignItems: "center",
                        flexDirection: "column",
                    }}>
                        <h5 >Add audio Note</h5>
                        <Grid item sx={{textAlign: 'center'}}>
                            <Grid item xs>
                            <MuiFileInput
                                value={valueInputFile}
                                placeholder="select a file"
                                onChange={handleFileChange} />
                            </Grid>
                            <Grid item xs>
                            <LoadingButton sx={{mt: 2}}
                                onClick={handleFileSubmit}
                                loading={loadingSending}
                                loadingIndicator="sending…"
                                type="submit"
                                variant="contained"
                                color="primary" >send</LoadingButton>

                            </Grid>
                        </Grid>

                    </CardContent>
                </Grid>
            </Grid>



                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleNote}
                >
                    <Form noValidate autoComplete="off" >
                        <Card sx={{mt: 5}}>
                            <CardContent sx={{
                                display: "Flex",
                                justifyContent: "center",
                                m: 1,
                                alignItems: "center",
                                flexDirection: "column",
                                }}>
                            <Field
                                component={FormTextField}
                                name="note"
                                label="Note"
                                multiline
                                rows={10}
                            />

                            <LoadingButton

                                loading={loading}
                                loadingIndicator="updating…"
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary" >Submit</LoadingButton>

                            </CardContent>
                        </Card>
                    </Form>
                </Formik>


                <h3>Last notes</h3>

            <div>{notes?.map((note, index) => {
                return(
                    <Card key={index} sx={{mt:5, p:2}}>
                        <CardContent>
                            <h5>Note {note.filepath ? ("audio"):("written")}</h5>
                            <ReactMarkdown>{!note.body ? (note.status):(note.body)}</ReactMarkdown>
                        </CardContent>
                    </Card>
                )
            })}</div>



        </Container>
    );
};

export default Note;
