import axios from "axios";
import authHeader from "./auth.header";
import {BACKEND_BASE_URL} from "../constants";


export interface IDAG {
    created_at?: string
    deleted_at?: string
    edges: Edge[]
    id: string
    name: string
    nodes: Node[]
    updated_at?: string
    active: boolean
}

export interface Edge {
    id: string
    child_id: string
    parent_id: string
}

export interface Node {
    id: string
    name: string
}

export interface ICreatedExecutor {
    name: string
    type: string
}

export interface IExecutor {
    name: string
}


export const getDAGs = () => {
    return axios.get<IDAG[]>(BACKEND_BASE_URL+"/admin/DAGs", { headers: authHeader() });
};

export const getDAG = ( DAGID: string) => {
    return axios.get<IDAG>(BACKEND_BASE_URL+"/admin/DAGs/"+ DAGID, { headers: authHeader() });
};

export const addDAG = (body: IDAG) => {

    return axios.post(BACKEND_BASE_URL+"/admin/DAGs" , body, { headers: authHeader() });
};

export const updateDAG = ( DAGID: string, body: IDAG) => {

    return axios.put(BACKEND_BASE_URL+"/admin/DAGs/"+ DAGID, body, { headers: authHeader() });
};

export const getCreatedExecutors = () => {
    return axios.get<ICreatedExecutor[]>(BACKEND_BASE_URL+"/admin/executors/created", { headers: authHeader() });
};

export const getExecutors = () => {
    return axios.get<IExecutor[]>(BACKEND_BASE_URL+"/admin/executors", { headers: authHeader() });
};

export const deleteDAG = ( dagID: string) => {

    return axios.delete(BACKEND_BASE_URL+"/admin/DAGs/"+ dagID,  { headers: authHeader() });
};