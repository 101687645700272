import React, { useState, useEffect } from 'react';
import {deleteCardType, getCardTypes, ICardType} from "../../services/cardTypes.service";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";
import { useConfirm } from 'material-ui-confirm';

const ListCardTypes: React.FC = () => {
    const [cardTypes, setCardTypes] = useState<ICardType[]>([]);
    const confirm = useConfirm();

    const fetchCardTypes = async () => {
        try {
            const {data} = await getCardTypes()
            setCardTypes(data)
        } catch (error) {

        }
    };

    useEffect(() => {
        fetchCardTypes();
    }, []);

    const handleDelete = async (id: any) => {
       confirm({ description: "This action is permanent!" }).then(async () => {
           try {
               await deleteCardType(id)
               enqueueSnackbar("Prompt deleted", {variant: 'success'});
               fetchCardTypes()
           } catch (e) {
               const error = e as AxiosError;
               enqueueSnackbar(error.toString(), {variant: 'error'});
           }
       }).catch(() => {
           enqueueSnackbar("delete canceled", {variant: 'warning'});
       });
    }



    return (
        <Container>
            <h1>Prompt</h1>
            <Table className="table">
                <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Executor</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {cardTypes.map((cardType) => (
                    <TableRow key={cardType.id}>
                        <TableCell>{cardType.id}</TableCell>
                        <TableCell>{cardType.name}</TableCell>
                        <TableCell>{cardType.executor}</TableCell>
                        <TableCell>{cardType.description}</TableCell>
                        <TableCell>
                            <Button variant="contained" component={Link} to={`/prompt/edit/${cardType.id}`} size="small" sx={{ mr:2 }} >Edit</Button>
                            <Button variant="contained" onClick={() =>handleDelete(cardType.id)} size="small">Delete</Button>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            <Button variant="contained" sx={{m: 2}} component={Link} to="/prompt/edit">Add New Prompt</Button>
        </Container>
    );
};

export default ListCardTypes;