import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {Link, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {isAdmin, isTokenExpired} from "../services/auth.service";
import * as AuthService from "../services/auth.service";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PsychologyIcon from '@mui/icons-material/Psychology';
import {ToggleThemePaletteMode} from "./ToggleThemePaletteMode";




function ResponsiveAppBar() {

    const navigate = useNavigate();
    const [admin, setAdmin] = React.useState(false);
    const [auth, setAuth] = React.useState(false);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const [anchorElAdmin, setAnchorElAdmin] = React.useState<null | HTMLElement>(null);
    const openAdmin = Boolean(anchorElAdmin);

    const handleClickAdmin = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElAdmin(event.currentTarget);
    };
    const handleCloseAdmin = () => {
        setAnchorElAdmin(null);
    };



    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        setAuth(!isTokenExpired())
        setAdmin(isAdmin())
        setInterval(() => {
            setAdmin(isAdmin())
            setAuth(!isTokenExpired())
        }, 5000)
    }, []);

    const handleLogout = () => {

        setAuth(false)
        AuthService.logout();
        navigate("/login")
    }


    return (
        <AppBar position="static" >

                <Toolbar disableGutters sx={{ mr: 1 ,  ml: 1 }}>
                    <PsychologyIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component={Link}
                        to="/Home"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Second Brain
                    </Typography>

                    <Box component="div" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        {auth && (<>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >

                        <MenuItem key="Home" component={Link} to="/Home" >
                            Home
                        </MenuItem>
                        <MenuItem key="knowledgeGraph" component={Link} to="/KnowledgeGraph" >
                            Knowledge graph
                        </MenuItem>
                        <MenuItem key="Note" component={Link} to="/Note" >
                            Note
                        </MenuItem>
                        {admin && (<MenuItem key="dag" component={Link} to="/dag">
                            DAG
                        </MenuItem>)}
                        {admin && (<MenuItem key="prompt" component={Link} to="/prompt">
                            Prompt
                        </MenuItem>)}

                        </Menu>
                        </>)}
                    </Box>
                    <PsychologyIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Second Brain
                    </Typography>
                    <Box component="div" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {auth && (<>
                        <Button
                            key="Home"
                            component={Link}
                            to="/Home"
                            sx={{ my: 2, color: 'white', display: 'block' }}
                        >
                            Home
                        </Button>
                        <Button
                            key="Note"
                            component={Link}
                            to="/Note"
                            sx={{ my: 2, color: 'white', display: 'block', textDecoration: "none" }}
                        >
                            Note
                        </Button>
                            <Button
                                key="KnowledgeGraph"
                                component={Link}
                                to="/KnowledgeGraph"
                                sx={{ my: 2, color: 'white', display: 'block', textDecoration: "none" }}
                            >
                                Knowledge graph
                        </Button>


                        {admin && (<>
                        <Button
                            sx={{ color: 'white', textDecoration: "none" }}
                            id="admin-button"
                            aria-controls={openAdmin ? 'admin-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openAdmin ? 'true' : undefined}


                            onClick={handleClickAdmin}
                            endIcon={<KeyboardArrowDownIcon />}
                        >
                            Admin
                        </Button>
                        <Menu
                            id="admin-menu"
                            anchorEl={anchorElAdmin}
                            open={openAdmin}
                            onClose={handleCloseAdmin}
                        >
                            <MenuItem onClick={handleCloseAdmin} disableRipple component={Link} to="/dag">
                                DAG
                            </MenuItem>
                            <MenuItem onClick={handleCloseAdmin} disableRipple component={Link} to="/prompt">
                                Prompt
                            </MenuItem>

                        </Menu>
                        </>)}
                        </>)}
                    </Box>

                    <Box component="div" sx={{ display: {  xs: 'none', md: 'flex' } }}>
                        <ToggleThemePaletteMode />
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="User" src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >

                            {auth ? (
                                <div>
                                    <MenuItem key="Profile"  component={Link} to="/profile">Profile</MenuItem>
                                    <MenuItem key="Logout"  onClick={handleLogout} >Logout</MenuItem>
                                </div>
                            ):(
                                <div>
                                    <MenuItem key="Profile"  component={Link} to="/login">Log In</MenuItem>
                                    <MenuItem key="Logout"  component={Link} to="/register">Sign Up</MenuItem>
                                </div>
                            )}
                        </Menu>
                    </Box>
                </Toolbar>

        </AppBar>
    );
}
export default ResponsiveAppBar;