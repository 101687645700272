import axios from "axios";
import {BACKEND_BASE_URL} from "../constants";
import authHeader from "./auth.header";


export default interface IUser {
    id?: any | null,
    username: string,
    email?: string,
    password: string,
    validated?: boolean
    role ?: string,
}



export const getUsersProfile = () => {
    return axios.get<IUser>(BACKEND_BASE_URL+ "/users/profile", { headers: authHeader() });
};

