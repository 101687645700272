import React, { useState, useEffect } from 'react';
import {deleteDAG, Edge, getDAGs, IDAG, Node} from "../../services/DAGs.service";
import {Canvas, CanvasContainerProps } from 'reaflow';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import Container from "@mui/material/Container";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {deleteCardType} from "../../services/cardTypes.service";
import {enqueueSnackbar} from "notistack";
import {AxiosError} from "axios";
import {useConfirm} from "material-ui-confirm";


const ListDAGs: React.FC = () => {
    const [DAGs, setDAGs] = useState<IDAG[]>([]);
    const [selectedDAG, setSelectedDAG] = useState<CanvasContainerProps>();
    const confirm = useConfirm();

    const fetchDAGs = async () => {
        try {
            const {data} = await getDAGs()
            setDAGs(data)
        } catch (error) {

        }
    };

    useEffect(() => {
        fetchDAGs();
    }, []);

    const viewGraph = (DAG: IDAG) => {
        setSelectedDAG({
            nodes : DAG.nodes.map((node: Node) => ({ id: node.id, text: node.name })),
            edges: DAG.edges.map((edge: Edge) => ({ id: edge.id, from: edge.parent_id, to: edge.child_id })),
            pannable:false,
            zoomable:false,
            direction: "RIGHT",
            readonly:true,
            animated:false,
            maxWidth:0,
            maxHeight:0,

        });

    };

    const handleDelete = async (id: any) => {
        confirm({ description: "This action is permanent!" }).then(async () => {
        try {
            await deleteDAG(id)
            enqueueSnackbar("delete deleted", {variant: 'success'});
            fetchDAGs()
        } catch (e) {
            const error = e as AxiosError;
            enqueueSnackbar(error.toString(), {variant: 'error'});
        }
        }).catch(() => {
            enqueueSnackbar("delete canceled", {variant: 'warning'});
        });
    }

    return (
        <Container >
            <h1>DAG</h1>
            <Table className="table">
                <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Default</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {DAGs.map((DAG) => (
                    <TableRow key={DAG.id}>
                        <TableCell>{DAG.id}</TableCell>
                        <TableCell>{DAG.name}</TableCell>
                        <TableCell><input  type="checkbox" disabled checked={DAG.active} /></TableCell>
                        <TableCell>
                            <Button variant="contained"  onClick={() =>viewGraph(DAG)} sx={{ mr:2 }} size="small">View</Button>
                            <Button variant="contained" component={Link} to={`/dag/edit/${DAG.id}`}  sx={{ mr:2 }} size="small">Edit</Button>
                            <Button variant="contained" onClick={() =>handleDelete(DAG.id)} size="small">Delete</Button>
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            <Button variant="contained" sx={{m: 2}} component={Link} to="/dag/edit">Add New DAG</Button>
            <div id="dag" style={{
                height: "500px",
                width: "100%",
                marginTop:"10px"
            }}>
            {selectedDAG &&
            <Canvas
                edges={selectedDAG.edges}
                nodes={selectedDAG.nodes}
                pannable={selectedDAG.pannable}
                zoomable={selectedDAG.zoomable}
                direction={selectedDAG.direction}
                readonly={selectedDAG.readonly}
                animated={selectedDAG.animated}
                maxWidth={selectedDAG.maxWidth}
                maxHeight={selectedDAG.maxHeight}
            />}
            </div>
        </Container>
    );
};

export default ListDAGs;