import React, { useState, useEffect } from 'react';
import {NavigateFunction, useNavigate, useParams} from 'react-router-dom';
import {addCardType, getCardType, ICardType, updateCardType} from "../../services/cardTypes.service";
import {Formik, Field, Form} from "formik";
import * as Yup from "yup";
import {AxiosError} from "axios";
import {Card, CardContent, Container} from '@mui/material';
import {FormTextField} from "../../components/FormTextField";
import {enqueueSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";
import {getExecutors, IExecutor} from "../../services/DAGs.service";
import MenuItem from "@mui/material/MenuItem";


interface EditCardTypeFormData {
    name: string;
    description: string;
    prompt: string;
    executor: string;
}

const EditCardType: React.FC = () => {

    const [loading, setLoading] = React.useState(false);
    const [cardType, setCardType] = useState<EditCardTypeFormData>();
    const { cardTypeId } = useParams<{ cardTypeId: string }>();
    const navigate: NavigateFunction = useNavigate();
    const [executor, setExecutor] = useState<IExecutor[]>();

    useEffect(() => {
        fetchExecutor()
        if (cardTypeId) {
            fetchCardType(cardTypeId);
        }
    }, [cardTypeId]);

    const fetchCardType = async (cardTypeId: string) => {
        try {
            const { data } = await getCardType(cardTypeId)
            setCardType(data)

        } catch (e) {

        }
    };

    const fetchExecutor = async () => {
        try {
            const {data} = await getExecutors()
            setExecutor(data)
        } catch (e) {

        }
    };

    const validationSchema = Yup.object().shape({
        prompt: Yup.string().required("This field is required!"),
        name: Yup.string().required("This field is required!"),
        description: Yup.string().required("This field is required!"),
    });


    const handleSubmit = async (formValue: EditCardTypeFormData) => {
        setLoading(true);
        try {
            if (cardTypeId){
                await updateCardType(formValue as ICardType, cardTypeId)
                enqueueSnackbar("Prompt updated", {variant: 'success'});

            } else {
                const { data } = await addCardType(formValue as ICardType)
                enqueueSnackbar("Prompt added", {variant: 'success'});
                navigate(`/prompt/edit/${data.id}`);
            }


        } catch (e) {
            const error = e as AxiosError;
            enqueueSnackbar(error.toString(), {variant: 'error'});
        }
        setLoading(false);
    }


    return (

        <Container >
            <h1>Prompt</h1>
            <Formik
                enableReinitialize
                initialValues={{
                    prompt: cardType?.prompt || "",
                    name: cardType?.name || "",
                    executor: cardType?.executor || "",
                    description: cardType?.description ||""
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >

                <Form noValidate autoComplete="off" >
                    <Card sx={{ mt: 2 }}>

                        <CardContent sx={{
                            display: "Flex",
                            justifyContent: "center",
                            m: 1,
                            alignItems: "center",
                            flexDirection: "column",
                        }}    >
                            <Field
                                component={FormTextField}
                                name="name"
                                label="Name"
                                className="form-control"
                            />

                            <Field
                                component={FormTextField}
                                name="description"
                                label="Description"
                                className="form-control"  />

                            <Field
                                component={FormTextField}
                                select
                                name="executor"
                                label="executor"
                                className="form-control"
                            >
                                <MenuItem value=''></MenuItem>
                                {executor && executor.map((option) => (
                                    <MenuItem key={option.name} value={option.name}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Field>

                            <Field
                                component={FormTextField}
                                name="prompt"
                                label="prompt"
                                multiline
                                rows={10}
                                className="form-control"
                            />



                            <LoadingButton
                                loading={loading}
                                loadingIndicator="updating…"
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary" >Submit</LoadingButton>

                        </CardContent>
                    </Card>
                </Form>
            </Formik>
        </Container>

    );
};


export default EditCardType;