import axios from "axios";
import authHeader from "./auth.header";
import {BACKEND_BASE_URL} from "../constants";



// GraphNode structure
export interface IGraphNode  {
    id:string
    type:string
    properties: Map<string, string>
}

// GraphRelationship structure
export interface  IGraphRelationship {
    source: IGraphNode
    target: IGraphNode
    type: string
    properties: Map<string, string>
}

export interface IKGraph {
    nodes: IGraphNode[]
    relationships: IGraphRelationship[]
}


export const getKGraph = () => {
    return axios.get<IKGraph>(BACKEND_BASE_URL+"/knowledgeGraph",{ headers: authHeader() });
};

