import React, {useEffect, useState} from "react";
import KGraph from "../components/KGraph";
import { Box } from "@mui/material";
import {getKGraph, IKGraph} from "../services/kgraph.service";


const KnowledgeGraph: React.FC = () => {

    const [kGraph, setKGrap] = useState<IKGraph>();
    const [loading, setLoading] = useState<boolean>(true);

    const fetchGraph = async () => {
        try {
            const { data } = await getKGraph()
            setKGrap(data)

            setLoading(false)
        } catch (error) {
            setLoading(true)
        }
    };

    useEffect(() => {
        // Run once when the component mounts
        fetchGraph()

    }, [])



    return (

            <Box component="div" sx={{
                position: 'fixed',
                width : 1,
                height: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'

            }} >

                <KGraph nodes={kGraph?.nodes} edges={kGraph?.relationships} loading={loading}  />

            </Box>

    );
}

export default KnowledgeGraph;