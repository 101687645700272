import axios from "axios";
import { BACKEND_BASE_URL} from "../constants";
import {jwtDecode, JwtPayload} from "jwt-decode";

// Create an Axios instance with default options


export const register = (username: string, password: string, email: string) => {
  return axios.post(BACKEND_BASE_URL+ "/auth/new", {
    username,
    email,
    password
  });
};

export const login =  async (username: string, password: string) => {
  try {
    const response = await axios.post(BACKEND_BASE_URL+"/auth/token", {}, {
      auth: {
        username: username,
        password: password
      }
    });
    if (response.data) {
      localStorage.setItem("token", response.data);
    }
    return response.status
  } catch (error) {
    throw error
  }
};

export const logout = () => {

  localStorage.removeItem("token");
};


export const isTokenExpired = () => {
  const token = localStorage.getItem("token")
  if (token) {
    const decodedToken = jwtDecode<JwtPayload>(token);
    if (decodedToken.exp) {
      const expirationTime = 1000 * decodedToken.exp; // convert to milliseconds
     return (expirationTime < Date.now())
    }
  }

  return true
};

type jwtTokenSB = {
  id : string
  roles : string[]
} & JwtPayload

export const isAdmin = () => {
  const token = localStorage.getItem("token")

  if (token) {
    const decodedToken = jwtDecode<jwtTokenSB>(token);
    if (decodedToken.roles.indexOf("ADMIN") > -1) {
      return true
    }
  }
  return false
};

export const GetID = () => {
  const token = localStorage.getItem("token")

  if (token) {
    const decodedToken = jwtDecode<jwtTokenSB>(token);
    return decodedToken.id
  }
    return ""
};